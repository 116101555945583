<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      @resetBtnClick="handleResetClick"
      :pageInfo="pageInfo"
    >
      <template #customForm>
        <el-col :span="24" style="padding: 18px">
          <el-form>
            <el-form-item label="Terminal" required>
              <el-select
                style="width: 100%"
                v-model="selectedTerminals"
                placeholder=" "
                disabled
                multiple
              >
                <el-option
                  v-for="item in terminalOption"
                  :key="item.id"
                  :label="item.sn"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <LTable
            ref="terminalTableRef"
            :initialCheckedTerminalList="initialCheckedTerminalList"
            :pageType="currentPageType"
            :batchType="batchType"
            :tenantId="tenantId"
            :terminal-list="terminalOption"
          ></LTable>
        </el-col>
      </template>
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseNewFormConfig } from './config/new'
import { baseUpdateFormConfig } from './config/edit'
import { baseViewFormConfig } from './config/view'
import LTable from './dynamic-table.vue'

import {
  ref,
  getCurrentInstance,
  watch,
  computed,
  onMounted,
  onUnmounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { cloneDeep } from 'lodash'
import { ElMessage } from 'element-plus'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global
// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'TemplateGroupView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('general.router-template-group-view'),
    pageType: 'view'
  },
  {
    routesName: 'TemplateGroupEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('general.router-template-group-edit'),
    pageType: 'edit',
    // edit page特有的属性 reset时不将表单置为空
    isResetFields: false
  },
  {
    routesName: 'TemplateGroupNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-template-group-new'),
    pageType: 'add'
  }
]
// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()
// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const currentPageType = ref(pageType)
const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})
currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const id = routes.params.id

const { appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
const bus = appContext.config.globalProperties.$bus

const baseFormRef = ref()
const batchType = ref('')
const tenantId = ref('')

const handleChange = async (form) => {
  const formData = baseFormRef.value.formData
  const { fieldName } = form
  if (fieldName === 'batchType') {
    batchType.value = formData.batchType?.join(',') || ''
  } else if (fieldName === 'tenant') {
    tenantId.value = formData.tenant?.join(',') || ''
  }
}

onMounted(() => {
  bus.on('linkageOptionsChange', handleChange)
})

onUnmounted(() => {
  // 组件销毁前注销事件监听
  bus.off('linkageOptionsChange', handleChange)
})

const terminalTableRef = ref(null)
const selectedTerminals = ref([])
const initialCheckedTerminalList = ref([])

watch(
  () => terminalTableRef?.value?.selectedRowItems,
  (newVal) => {
    if (newVal) {
      selectedTerminals.value = newVal?.map((item) => item.id)
    }
  }
)

const terminalOption = ref([])

const initialListData = async () => {
  terminalOption.value = await store.dispatch('param/getPageForGroupList', {
    batchType: '',
    tenantId: '',
    groupType: 'terminal_param',
    groupId: id
  })
}

const initialTerminalParams = ref([])
// 5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  await initialListData()
  const group = await store.dispatch('param/getTemplateGroupById', {
    id
  })
  group.tenantId = store.getters.tenantDataMap[group?.tenantId]

  group.tenant = group.tenant && group.tenant?.split(',')
  group.batchType = group.batchType && group.batchType.split(',')
  pageInfo.value = group

  const terminalList = group.terminalId && group.terminalId.split(',')
  selectedTerminals.value = terminalList
  initialTerminalParams.value = terminalList
  initialCheckedTerminalList.value = terminalList

  pageInfo.value = group
  // nextTick(() => {
  //   terminalTableRef.value.loadCheckedItems(initialCheckedTerminalList.value)
  // })
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  initialListData()
  pageInfo.value = {}
}

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  let url = 'handleAddTemplateGroup'
  const form = cloneDeep(data)

  if (!selectedTerminals.value.length) {
    return ElMessage.warning('Please choose at least one terminal in the list.')
  }

  form.tenant = data.tenant && data.tenant.length ? data.tenant.join(',') : ''
  form.batchType =
    data.batchType && data.batchType.length ? data.batchType.join(',') : ''

  form.terminalId =
    selectedTerminals.value && selectedTerminals.value.length
      ? selectedTerminals.value.join(',')
      : ''
  if (pageType === 'edit') {
    form.id = id
    url = 'handleUpdateTemplateGroup'
  }
  handleMessageCommit(`param/${url}`, form).then(() => {
    router.push('/template/group')
  })
}

// 重置
const handleResetClick = () => {
  if (pageType === 'edit') {
    selectedTerminals.value = initialCheckedTerminalList.value
    nextTick(() => {
      terminalTableRef.value.loadCheckedItems(initialCheckedTerminalList.value)
    })
  } else {
    selectedTerminals.value = []
    // 调用子组件的清空勾选的函数
    terminalTableRef.value.clearSelection()
  }
}
</script>

<style scoped></style>
